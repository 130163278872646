/* CSS Document */

@font-face {
  font-family: "loom24";
  src:url(../../fonts/loom24.eot);
  src:url(../../fonts/loom24.eot?#iefix) format("embedded-opentype"),
  url(../../fonts/loom24.woff) format("woff"),
  url(../../fonts/loom24.ttf) format("truetype"),
  url(../../fonts/loom24.svg#1445769649) format("svg");
  font-weight: normal;
  font-style: normal;
}
[data-icon]:before {
	font-family: "loom24" !important;
	content: attr(data-icon);
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
	font-family: "loom24" !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.icon-acorn:before {
	content: "\61";
}
.icon-arrows:before {
	content: "\62";
}
.icon-award:before {
	content: "\63";
}
.icon-calendar:before {
	content: "\64";
}
.icon-cat:before {
	content: "\65";
}
.icon-gallery:before {
	content: "\66";
}
.icon-heart:before {
	content: "\67";
}
.icon-watercan:before {
	content: "\68";
}
.icon-dog:before {
	content: "\69";
}
.icon-basket:before {
	content: "\6a";
}
.icon-sheep:before {
	content: "\6b";
}
.icon-logo:before {
	content: "\6c";
}
.icon-angle-down:before {
	content: "\6d";
}
.icon-angle-left:before {
	content: "\6e";
}
.icon-angle-right:before {
	content: "\6f";
}
.icon-angle-up:before {
	content: "\70";
}
.icon-plus-circled:before {
	content: "\71";
}
.icon-minus-circled:before {
	content: "\72";
}
.icon-youtube-play:before {
	content: "\73";
}
.icon-euro:before {
	content: "\74";
}
.icon-email-envelope:before {
	content: "\75";
}
.icon-telephone:before {
	content: "\76";
}
.icon-star-two:before {
	content: "\77";
}
.icon-align-center:before {
	content: "\78";
}
.icon-comment:before {
	content: "\79";
}
.icon-share:before {
	content: "\7a";
}
.icon-print:before {
	content: "\41";
}
.icon-alert:before {
	content: "\42";
}
.icon-check-mark:before {
	content: "\43";
}
.icon-newspaper:before {
	content: "\44";
}
.icon-google:before {
	content: "\46";
}
.icon-facebook:before {
	content: "\45";
}
.icon-handshake:before {
	content: "\47";
}
.icon-instagrem:before {
	content: "\48";
}
.icon-facebook-square:before {
	content: "\49";
}
.icon-twitter:before {
	content: "\4a";
}
.icon-camera:before {
	content: "\4b";
}
.icon-close:before {
	content: "\4c";
}
.icon-add-circle:before {
	content: "\4d";
}
.icon-outlined-delete:before {
	content: "\4e";
}
.icon-outlined-checked:before {
	content: "\4f";
}
.icon-down-circle:before {
	content: "\50";
}
.icon-down:before {
	content: "\51";
}
.icon-link:before {
	content: "\52";
}
.icon-map-marker:before {
	content: "\53";
}
.icon-clock-o:before {
	content: "\54";
}
.icon-calendar-1:before {
	content: "\55";
}
.icon-cog:before {
	content: "\56";
}
a {
	&:hover, &:active, &:focus, &:visited {
		text-decoration: none;
		outline:none;
	}
}
body, html{
	overflow-x:hidden;
	background:#48b648;;
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
	font-family: 'open-sans', sans-serif;
}
.modal-header {
	padding: 10px 15px;
	border-bottom: none;
	min-height: 16.42857143px;
	background-color: #48b648;
	color: #ffffff;
	text-transform: uppercase;
}
.red {
	color: #e3002e;
}
.redB {
	background-color: #e3002e;
}
.content-container a {
	color:#000;
	-webkit-transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
	   -moz-transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
		 -o-transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
			transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955); /* easeInOutQuad */

	-webkit-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
	   -moz-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
		 -o-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
			transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955); /* easeInOutQuad */
}
p.bigger{
  font-size: 16px;
  line-height: 25px;
}
.content-container a:hover{
	color:#48b648;
	text-decoration:none;
}
.main-container{
	max-width:1250px;
	background:#fff;
}
.header .green .cont{
  	height: 17vw;
	width:100%;
	background-color:#48b648;
	background-image: url(../../img/logo.png);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}
.green a:hover{
	text-decoration: none;
}
.header{
	margin-bottom:10px;
}
.content .green .cont, .content .green .menu-cont{
	width:100%;
	background:#48b648;
}
.content .green .cont.white{
	width:100%;
	background:#fff;
  padding-bottom: 10px;
}
.main-menu {

}
.menu-cont ul{
	margin:0;
	padding:0;
	position:relative;
}
.menu-cont ul li span{
	display:inline-block;
	vertical-align:top;
}
.menu-cont ul li .color{
	width:10px;
	height:60px;
	background:#fff;
}
.menu-cont ul li{
	height:60px;
	line-height:60px;
	font-size:12px;
	text-transform:uppercase;
	list-style:none;
	white-space:nowrap;
	background-position: 100% 0;
    background-size: 200% 100%;
	-webkit-transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
	   -moz-transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
		 -o-transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
			transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955); /* easeInOutQuad */

	-webkit-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
	   -moz-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
		 -o-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
			transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955); /* easeInOutQuad */
}
.menu-cont ul li:hover{
	 background-position: 0 0;
}
.menu-cont ul li .tables{
	display:table;
	float:none;
	margin-bottom:0;
	height:60px;
}
.menu-cont ul li .table-cont{
	width:60%;
}
.menu-cont ul li .text{
	line-height:15px;
	display:table-cell;
	vertical-align:middle;
	float:none;
	white-space: initial;
}
.menu-cont ul li a .icon{
	font-size:35px;
	vertical-align:middle;
	padding:0 5%;
	width:25%;
	text-align:center;
}

.menu-cont ul li a{
	color:#fff;
	float:left;
	text-decoration:none;
	height:60px;
	-webkit-transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
	   -moz-transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
		 -o-transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
			transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955); /* easeInOutQuad */

	-webkit-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
	   -moz-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
		 -o-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
			transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955); /* easeInOutQuad */
}
.menu-cont ul li ul{
	position:absolute;
	top:0px;
	background:#fff;
	z-index:100;
	right:0px;
	width:72%;
	display:none;
	border-left:2px solid;
	overflow-y:scroll;
}
.menu-cont ul li ul{
	height:100%;
}
.menu-cont ul li ul li{
	font-size:15px;
	font-weight:300;
	text-transform:none;
	height:40px;
	line-height:40px;
	border-bottom:1px solid #e9e2d6;
	background-position: 100% 0;
    background-size: 200% 100%;
	-webkit-transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
	   -moz-transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
		 -o-transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
			transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955); /* easeInOutQuad */

	-webkit-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
	   -moz-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
		 -o-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
			transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955); /* easeInOutQuad */
}
.menu-cont ul li ul li a{
	text-decoration:none;
	padding:0px 15px;
	height:40px;
	color:#353535;
	font-weight:300;
	-webkit-transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
	   -moz-transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
		 -o-transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
			transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955); /* easeInOutQuad */

	-webkit-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
	   -moz-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
		 -o-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
			transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955); /* easeInOutQuad */
}

.menu-cont ul li ul li:hover a{
	background:#e9e2d6;
}
.menu-cont ul li a{
	display:inline-block;
	width:100%;
}
.searchFormContainer {
	margin-bottom:10px;
}
.logo-cont span.icon-logo{
	font-size:150px;
	color:#fff;
	vertical-align:top;
}
.logo-cont .logo-text{
	display:block;
	color:#fff;
	font-family: 'Amatic SC', cursive;
  font-weight: 400;
	margin-top:-90px;
	font-size:30px;
	line-height:29px;
	text-align:center;
}
.logo-cont{
	text-align:center;
}
.clear{
	clear:both;
	float:none;
}
.green-line{
	background:#48b648;
	display:block;
	width:10px;
	height:100%;
	position:absolute;
	z-index:100;
}
.profile a {
	color:#000;
	text-decoration:none;
}
.profile .white {
	height:60px;
	color: #48b648;
  	background: #ffffff;
	border:1px solid #48b648;
	margin-bottom:10px;
	padding: 0 20px;
	font-size:34px;
	line-height:60px;
	font-family: 'Amatic SC', cursive;
  	text-transform: uppercase;
	font-weight: 400;
	text-align:left;
	display: block;
	position: relative;
	-webkit-transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
	   -moz-transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
		 -o-transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
			transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955); /* easeInOutQuad */

	-webkit-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
	   -moz-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
		 -o-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
			transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955); /* easeInOutQuad */
}
.profile .white.green{
  text-transform: uppercase;
	background:#48b648;
	color:#fff;
}
.profile .white.green:hover{
	background:#fff;
	color:#48b648;
}
.profile .white:hover, .profile .open {
	background:#48b648;
	color:#fff;
}
.profile .white:hover .notification {
	color:#48b648;
	background:#fff;
}
.profile .white .notification {
	display:inline-block;
	font-family: 'open-sans', sans-serif;
	font-size:15px;
	vertical-align:middle;
	width:30px;
	height:30px;
	background:#48b648;
	color:#fff;
	line-height:30px;
	-webkit-border-radius: 100px;
	-moz-border-radius: 100px;
	border-radius: 100px;
	position: absolute;
	right:15px;
	top:50%;
	margin-top: -15px;
	text-align: center;
	-webkit-transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
	   -moz-transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
		 -o-transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
			transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955); /* easeInOutQuad */

	-webkit-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
	   -moz-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
		 -o-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
			transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955); /* easeInOutQuad */
}
.profile .white .notification.redB {
	background-color: #e3002e;
	color: #ffffff;
}
.profile .notificationsContainer {
	position: relative;
}
.profile .notifications {
	padding: 0;
	margin: 0;
	overflow: auto;
	display: none;
	list-style: none;
}
.profile .notifications li {
	padding: 0;
	margin: 0;
}
.profile .notifications.open {
	display: block;
	z-index: 15;
	background-color: #f4f4f6;
	width: 100%;
	left: 0px;
	top: 60px;
	position: absolute;
}
.allNotifications {
	z-index: 15;
	padding: 0;
	overflow: auto;
	list-style: none;
	margin-left: -5px;
	margin-right: -5px;
	margin-bottom: 0px;
	li {
		a.icon {
			position: absolute;
			top:5px;
			right:10px;
			width: 20px;
			height:20px;
			line-height: 20px;
			font-size: 20px;
			color: #48b648;
			&:hover {
				background-color: transparent;
			}
		}
	}

}
.profile .notifications li a.content, .allNotifications li a.content {
	display: block;
	overflow: auto;
	background-color: #f4f4f6;
	border-bottom: 1px solid #ffffff;
	overflow: hidden;
}

.profile .notifications .profile-image, .allNotifications .profile-image {
	margin: 10px 0px 10px 10px;
	float: left;
}
.profile .notifications .notificationMessage, .allNotifications .notificationMessage{
	padding: 0;
	margin: 0;
	font-size: 12px;
	list-style: none;
	position: absolute;
	left: 70px;
}
.profile .notifications .notificationMessage .name, .allNotifications .notificationMessage .name {
	margin-top: 5px;
	color: #48b648;
	font-size: 14px;
	font-weight: 700;
	line-height: 1.2em;
}
.profile .notifications .load-more, .profile .notifications .big-butt {
	margin:0;
	font-size: 12px;
}
.profile .notifications .load-more:hover, .profile .notifications .big-butt:hover {
	color: #ffffff;
	text-decoration: none;
}
.allNotifications li {
	margin-bottom: 10px;
}
.allNotifications li li {
	margin-bottom: 0px;
}
.profile .notifications li a.content:hover, .allNotifications li a.content:hover {
	background-color: #48b648;
}
.profile .notifications li a.content:hover .notificationMessage, .profile .notifications li a.content:hover .notificationMessage .name, .allNotifications li a.content:hover .notificationMessage .name, .allNotifications li a.content:hover .notificationMessage, .allNotifications li:hover .icon {
	color: #ffffff;
}
.allNotifications li a.icon:hover {
	color:#48b648;
}
.profile .light-green {
	position:relative;
	height:60px;
	border:1px solid #48b648;
	background:#e2f3e2;
	margin-bottom:10px;
	border-left:none;
	line-height:55px;
	cursor:pointer;
	-webkit-transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
	   -moz-transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
		 -o-transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
			transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955); /* easeInOutQuad */

	-webkit-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
	   -moz-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
		 -o-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
			transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955); /* easeInOutQuad */
}
.profile .light-green:hover{
	background:#48b648;
	border-color:#48b648;
	color:#fff;
}
.profile-image{
	height:45px;
	width:45px;
	vertical-align:middle;
	background-repeat:no-repeat;
  	background-size: cover;
	-o-background-size: cover;
	-moz-background-size: cover;
	-webkit-background-size:cover;
	background-position: center center;
	-webkit-border-radius: 100px;
	-moz-border-radius: 100px;
	border-radius: 100px;
	display:inline-block;
	margin-left:20px;
    background-color:#48b648;
  	z-index: 10;
}
.profile .light-green .name-cont{
	display:inline-block;
	font-family: 'Amatic SC', cursive;
  font-weight: 400;
	margin-left:10px;
    font-size: 25px;
	vertical-align:middle;
  text-transform: uppercase;
}
.profile .name-cont small{
	font-size: 14px;
	vertical-align: middle;
	text-align:center;
	color: #48b648;
}
.profile .light-green .dropdown{
	font-size: 24px;
	margin-top: -0px;
	margin-bottom: -7px;
	vertical-align: middle;
	display: inline-block;
}
li.main-heading {
  line-height: 60px !important;
  height: 60px !important;
  padding-left: 15px;
  color: #fff;
  text-transform:uppercase !important;
  font-size:12px !important;
  font-weight:600 !important;
}
.content-container{
	padding-right:10px;
}
.one-cont{
	-webkit-transition: all 500ms cubic-bezier(0.680, 0, 0.265, 1); /* older webkit */
-webkit-transition: all 600ms cubic-bezier(0.680, -0.550, 0.265, 1.550);
   -moz-transition: all 600ms cubic-bezier(0.680, -0.550, 0.265, 1.550);
     -o-transition: all 600ms cubic-bezier(0.680, -0.550, 0.265, 1.550);
        transition: all 600ms cubic-bezier(0.680, -0.550, 0.265, 1.550); /* easeInOutBack */

-webkit-transition-timing-function: cubic-bezier(0.680, 0, 0.265, 1); /* older webkit */
-webkit-transition-timing-function: cubic-bezier(0.680, -0.550, 0.265, 1.550);
   -moz-transition-timing-function: cubic-bezier(0.680, -0.550, 0.265, 1.550);
     -o-transition-timing-function: cubic-bezier(0.680, -0.550, 0.265, 1.550);
        transition-timing-function: cubic-bezier(0.680, -0.550, 0.265, 1.550); /* easeInOutBack */
}
.one-cont.small{
	-webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform:  scale(0);
    -ms-transform:  scale(0);
    transform:  scale(0);
}
.inner-container{
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
	padding-bottom:150%;
	background-position: center;
	overflow:hidden;
	position:relative;
	/*border:2px solid #fff;
	-webkit-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.2);
	-moz-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.2);
	box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.2);*/
	margin-bottom:10px;
}
.head-title{
	position:absolute;
	background:rgba(255,255,255,0.75);
	padding:5px 10px;
	width:100%;
    padding-right: 20%;
	font-size:12px;
	line-height:20px;
	white-space:nowrap;
	text-overflow:ellipsis;
	overflow:hidden;
    position: absolute;
    .status-tag{
      width: 25px;
      height: 25px;
      text-align: center;
      line-height: 25px;
      color: #fff !important;
      position: absolute;
      top: 2.5px;
      right: 2.5px;
      -webkit-border-radius: 50px;
      -moz-border-radius: 50px;
      border-radius: 50px;
      background: #000;
      font-weight: normal;
    }
}
.footer-container .legends ul li {
  padding: 10px !important;
  font-size: 10px !important;
}
.footer-container .status-tag{
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  color: #fff !important;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  background: #000;
  font-weight: bold;
  display: inline-block;
  margin-right: 5px;
  font-size: 12px;
}

.bottom-text{
  overflow: hidden;
  position: absolute;
  font-size: 17px;
  bottom: 0;
  width: 100%;
  height: 75px;
  background: rgba(0, 0, 0, 0.65);
  color: #fff;
  padding: 5px 10px;
  line-height: 1.2em;
	-webkit-transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
	   -moz-transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
		 -o-transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
			transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955); /* easeInOutQuad */

	-webkit-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
	   -moz-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
		 -o-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
			transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955); /* easeInOutQuad */
}
.inner-container:hover .bottom-text{
	bottom:-100%;
}
.one-cont a{
	color:#000;
	text-decoration:none;
}
.ads h2{
	background:#f9a818;
	color:#fff;
	padding:15px;
	margin-top:0px;
	margin-bottom:10px;
	font-family: 'Amatic SC', cursive;
  	font-weight: 400;
	font-size:36px;
  	text-transform: uppercase;
}

.ads .eventDate {
	font-size: 14px;
	display: block;
}

.ads .intro{
	font-size:14px;
	margin-top:0px;
	margin-bottom:10px;
	padding:0 15px;
}
a.product-btn, a.product-btn:focus, a.product-btn:active, a.product-btn:visited {
  font-size: 30px;
  line-height: 30px;
  font-family: 'Amatic SC', cursive;
  margin-bottom: 10px;
  background-color: #f9a818;
  text-transform: uppercase;
  border: 1px solid #f9a818;
}
a.product-btn:hover {
  font-size: 30px;
  line-height: 30px;
  font-family: 'Amatic SC', cursive;
  margin-bottom: 10px;
  background-color: #fff;
  text-transform: uppercase;
  border: 1px solid #f9a818;
  color:#f9a818!important;
}
.news-title{
  font-size: 30px!important;
  padding: 10px 15px !important;
  small{
	color: #fff;
	font-family: 'open-sans', sans-serif;;
	font-size: 10px;
	text-transform: uppercase;
	display: inline-block;
	margin-bottom: -20px;
  }
}
#filtering{
	width:100%;
	margin-bottom:10px;
}
.select2{
	margin-bottom:10px;
}
.select2-container--default .select2-search--inline .select2-search__field{
  	font-size:18px;
}
.select2-container--default .select2-selection--multiple {
  	padding: 0 10px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
}
.select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: #48b648;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
	color:rgba(243,243,243,1.00);
}
.select2-container--default .select2-selection--multiple .select2-selection__choice{
	font-size:14px;
	-webkit-border-radius: 0px;
	-moz-border-radius: 0px;
	border-radius: 0px;
	padding:5px 10px;
	text-transform:uppercase;
	background:#f9a818;
	color:#fff;
	font-weight:600;
  border:none;
}
.select2-dropdown {
  background-color: white;
  border: 1px solid #48b648;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background: #48b648;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered{
	padding-left:0px;
}
.select2-container--default .select2-results > .select2-results__options{
	-webkit-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.2);
	-moz-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.2);
	box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.2);
}

.load-more, .big-butt {
	margin-top:10px;
	text-transform:uppercase;
	text-align:center;
	display:block;
	padding:10px;
	background:#eeeeed;
	margin-bottom:10px;
	vertical-align:middle;
	color:#353535;
	font-size:16px;
	-webkit-transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
	   -moz-transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
		 -o-transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
			transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955); /* easeInOutQuad */

	-webkit-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
	   -moz-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
		 -o-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
			transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955); /* easeInOutQuad */
	cursor:pointer;
}
a.load-more:hover, a.big-butt:hover{
	background:#f9a818;
	color:#fff;
}
.iconfont{
	color:#fff;
	vertical-align:middle;
	font-size:20px;
	padding-right:10px;
	margin-top:5px;
}
.loader{
	text-align:center;
	display:none;
}
.loader img{
	display:inline-block;
}
.bannerBetweenPosts {
	margin-bottom: 10px;
	a {
		padding-bottom: 21.5%;
		background-position: center;
		background-size: cover;
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;
		ackground-repeat: no-repeat;
		display: block;
		transition:none;
	}
	a:hover {
		background: ;
	}
}

.follow {
	font-size: 12px;
	height:20px;
	line-height: 20px;
	text-decoration: none;
	&:active {
		text-decoration: none;
	}
	&:visited {
		text-decoration: none;
	}
	&:focus {
		text-decoration: none;
	}
	&:before {
		text-decoration: none;
		line-height: 19px;
		vertical-align: middle;
	}
	span {
		margin-left: 5px;
		font-size: 12px;
		line-height: 1em;
	}
	.show {
		display: inline-block!important;
	}
	.hide {
		display: none;
	}
}
.coverContainer {
	background-color: #f2f2f2;
	h4 {
		color: #48b648;
		font-family: 'Amatic SC', cursive;
		text-transform: uppercase;
		font-weight: 400;
		font-size: 34px;
		margin:0;
	}
	.coverAbout {
		padding: 15px;
		font-size: 12px;
		h4 {
			margin-bottom: 0.35em;
		}
		.details {
			ul {
				margin:0;
				padding: 0;
				list-style: none;
				span {
					color: #48b648;
				}
			}
		}
	}
}
.profile-image-container {
	.profile-image {
		position: relative;
		height: 100px;
		width: 100px;
		vertical-align: middle;
		background-repeat: no-repeat;
		background-size: cover;
		-o-background-size: cover;
		-moz-background-size: cover;
		-webkit-background-size: cover;
		background-position: center center;
		border-radius: 100px;
		background-color: #ffffff;
		.profiles {
			display: none;
			position: absolute;
			color: #48b648;
			top: 110px;
			list-style: none;
			padding: 0;
			margin: 0;
			left: 0px;
			width: 220px;
			z-index: 9;
			text-align: left;
			li {
				background-color: #ffffff;
				border: 1px solid #d7d6d6;
				margin-bottom: -1px;
				text-transform: capitalize;
			}
			li a {
				height: 40px;
				line-height: 40px;
				display: block;
				padding: 0 10px;
				&:hover {
					color: #ffffff;
					background-color: #48b648;
				}
			}
			.profile-image {
				width: 30px;
				height: 30px;
				display: inline-block;
				margin: 0px 10px 0px 0px;
			}
		}
		.chooseProfile {
			background-color: #e3002e;
			width: 30px;
			height: 30px;
			-webkit-border-radius: 100px;
			-moz-border-radius: 100px;
			border-radius: 100px;
			line-height: 30px;
			text-align: center;
			vertical-align: middle;
			text-decoration: none;
			color: #ffffff;
			font-size: 15px;
			position: absolute;
			bottom: 0px;
			right: 0;
			z-index: 7;
			&:before {
				line-height: 31px;
				z-index: 10;
			}
		}
	}
}
.coverPicture {
	position: relative;
	height: 300px;
	width: 100%;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-position:center;
	background-image: url(../../img/default-cover.jpg);
	color: #ffffff;
	.follow {
		color: #ffffff;
		position: absolute;
		bottom: 15px;
	}
	.addCoverPicture {
		color: #ffffff;
		text-decoration: none;
		opacity: 0.7;
		position: absolute;
		right: 15px;
		bottom: 10px;
		font-size: 20px;
		line-height: 1em;
		text-shadow: 0 0 3px rgba(0,0,0,.8);
		&:hover {
			opacity: 1;
			color: #ffffff;
		}
	}
	.profile-image-container {
		position: absolute;
		right: -50px;
		top: 50%;
		margin-top: -50px;
	}
	h4 {
		color: #ffffff;
		padding-top: 45%;
		padding-right:60px;
		span {
			font-family: 'open-sans', sans-serif;
			display: block;
			font-size: 12px;
			text-transform: none;
			margin-top: 5px;
			a {
				color:#ffffff;
				text-decoration: none;
			}
		}
	}
	.name {
		padding-left: 20px;
		height: 300px;
		background-color: rgba(0,0,0,0.6);
		margin-left: 5px;
	}
}
.addPostContainer {
	padding: 20px 0px 10px 0px;
	border-top: 2px solid #48b648;
	border-bottom: 1px solid #48b648;
	#attachmentPreviewContainer {
		overflow: hidden;
		max-height: 800px;
		width: 100%;
		.attachmentPreview {
			width:100%;
			margin-bottom: 15px;
			max-height: 800px;
		}
	}
	.tab-content {
		.tab-pane {
			label {
				font-weight: 400;
			}
		}
	}
	.profile-image-container {
		padding: 0;
		margin-bottom: 10px;
		display: inline-block;
		text-align: center;
		width: 100%;
	}
	#addMessage {
		border: 1px solid #d7d6d6;
		background-color:#f4f4f6;
		padding: 6px 12px;
		width: 100%;
	}
	.addAttachmentContainer {
		margin-top: 5px;
		.btn {

		}
		.btn .icon {
			padding-right: 5px;
			vertical-align: middle;
			font-size: 14px;
			color: #48b648;
			line-height: normal;
		}
		.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
			opacity: .5;
		}
		.btn.disabled .icon, .btn[disabled] .icon, fieldset[disabled] .btn .icon {
			color: #000;
		}
	}
}
.likeContainer, .shareContainer {
	margin: 10px 0;
	a {
		text-decoration: none;
		color: #48b648;
		border: 1px solid #48b648;
		height: 40px;
		line-height: 40px;
		display: block;
		padding: 0 15px;
		margin: 0 -15px 0 0;
		&:hover {
			color: #48b648;
			background-color: #d9f1d9;
		}
		&.active {
			color: #ffffff;
			background-color: #48b648
		}
	}
}
.shareContainer {
	margin: 0 0 20px 0;
}
.postDetailsMobile {
	&:after {
		content: ".";
		visibility: hidden;
		display: block;
		height: 0;
		clear: both;
	}
	.profile-image {
		float: left;
		margin: 10px 0;
	}
	.profile-name {
		float: left;
		margin: 14px 0 0 10px;
		a {
			font-size: 16px;
			display: block;
			font-weight: 700;
			color: #48b648;
			margin-bottom: 3px;
		}
	}
	.likeContainer, .shareContainer {
		margin: 10px 0;
		a {
			padding: 0 15px;
			margin: 0;
			&:hover {
				color: #48b648;
				background-color: #d9f1d9;
			}
			&.active {
				color: #ffffff;
				background-color: #48b648
			}
		}
	}
	.likeCounter, .shareCounter {
		float: right;
		text-align: right;
		display: inline-block;
	}
	.shareContainer {
		margin: 10px 0;
	}
	.tooltip-inner {
		text-align: left;
		min-width: 170px;
	}
	.tooltip-inner ul {
		list-style: none;
		padding: 0;
		margin:0;
		font-size: 12px;
	}
}
.allPostsContainer {
	h2 {
		margin-top:10px;
	}
}
.postContainer {
	margin-top: 10px;
	margin-bottom: 10px;
	border-top: 1px solid #d7d6d6;
	.postContent {
		padding: 12px 15px 15px 15px;
		border-right: 1px solid #d7d6d6;
		.postText {
			margin-bottom: 12px;
			font-weight: 600;
			.date {
				font-weight: 400;
				font-size: 12px;
				color: #9197a3;
				margin-left: 5px;
			}
		}
		.postAttachment {
			overflow: hidden;
			max-height: 800px;
			img {
				max-height: 800px;
			}
			.postLinkTitle {
				margin: 15px 0;
				display: block;
				font-weight: 600;
			}
		}
	}
	.postDetails {
		padding: 5% 10% 1% 15%;
		text-align: right;
		.profile-name {
			margin: 20px 0;
			span {

			}
			a {
				margin: 0 0 5px 0;
				display: block;
				font-size: 20px;
				font-weight: 700;
				text-decoration: none;
				color: #48b648;
			}
		}
		.likeCounter, .shareCounter {
			display: inline;
			&:after {
				content:"\000A";
				white-space: pre;
			}
		}
		.tooltip-inner {
			text-align: left;
			min-width: 170px;
		}
		.tooltip-inner ul {
			list-style: none;
			padding: 0;
			margin:0;
			font-size: 12px;
		}
	}
	.postContent {
		border-left: 1px solid #d7d6d6;
		border-bottom: 1px solid #d7d6d6;
		.icon-close {
			position: absolute;
			right: 20px;
			top: 15px;
			font-size: 10px;
			line-height: 1em;
			height: 10px;
			width: 10px;
			color: #9197a3;
			text-decoration: none;
		}
	}
	.commentsContainer {
		width: 100%;
		.commentBoxContainer {
			width: 100%;
			border-top: none;
			border-left: 1px solid #d7d6d6;
			border-right: 1px solid #d7d6d6;
			border-bottom: 1px solid #d7d6d6;
			margin-bottom: 10px;
			.commentBox {
				border:none;
				width: 100%;
				height: 100%;
				background: #FFFFFF;
				padding: 5px 7px;
				outline: none;
			}
			.small {
				color: #9197a3;
				font-size: 9px;
				margin: 0 0 5px 7px;
			}
		}
		a.big-butt  {
			font-size: 12px;
			margin:0px;
		}
		a.load-more {
			font-size: 12px;
			margin:0px;
		}
		.error {
			list-style: none;
			padding:5px 0 0 0;
			margin: 0px 0 0 10px;
			color: darkred;
			li {
				list-style: none;
				padding: 0;
				margin:0;
			}
		}
		.postComments {
			list-style: none;
			padding: 0;
			margin: 0;
			li {
				position: relative;
				overflow: auto;
				padding: 0;
				margin: 0;
				background-color: #f4f4f6;
				list-style: none;
				min-height: 65px;
				border-bottom: 1px solid #FFFFFF;
				.icon-close {
					position: absolute;
					right: 10px;
					top: 10px;
					font-size: 10px;
					color: #9197a3;
					text-decoration: none;
					&:hover {
						color: #7a808c;
					}
				}
				.profile-image {
					margin:0;
					padding: 0;
					position: absolute;
					left: 10px;
					top: 10px;
				}
				.postComment {
					float: left;
					margin: 10px 10px 10px 75px;
					.commentDetails {
						line-height: 1.2em;
						margin-top: 5px;
						margin-bottom: 10px;
						a {
							color: #48b648;
							display: block;
							text-decoration: none;
							margin: 0;
						}
						span {
							color: #9197a3;
							font-size: 9px;
						}
					}
				}
			}
		}
		.postComments li:nth-child(n+5) {
			display: none
		}
	}
}
#infscr-loading {
	text-align: center;
	padding: 15px 0;
}
.itemContainer {
	background-color: #f4f4f6;
	border: 1px solid #d7d6d6;
	padding:5px;
	min-height: 200px;
	.icon-plus-circled {
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -50px;
		margin-top: -50px;
		font-size: 100px;
		line-height: 100px;
		width: 100px;
		height: 100px;
		color: #48b648;
		display: block;
	}
}
.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.news-container a {
	color:#000;
	text-decoration:none;
}
.news-6 .image-container {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
	background-position:center;
	padding-bottom:50%;
	position:relative;
	overflow:hidden;
}
.news-3 .image-container{
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
	background-position:center;
	padding-bottom:100%;
	position:relative;
	overflow:hidden;
}
.news-6 .image-container .bottom-info, .news-3 .image-container .bottom-info{
	padding:10px;
	color:#000;
	position:absolute;
	bottom:-100%;
	background:rgba(255,255,255,0.75);
	width:100%;
	-webkit-transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
	   -moz-transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
		 -o-transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
			transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955); /* easeInOutQuad */

	-webkit-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
	   -moz-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
		 -o-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
			transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955); /* easeInOutQuad */
}
.news-6:hover .bottom-info, .news-3:hover .bottom-info{
	bottom:0px;
}
.news-6 .image-container ul, .news-3 .image-container ul{
	padding:0;
	margin:0;
}
.news-content{
	padding-left:10px;
	margin-top:5px;
	border-left:#48b648 solid 1px;
  p{
    color: #484848;
  }
}
.news-content h3{
	font-size:16px;
	line-height:18px;
    margin-top: 10px;
    font-weight: 600;
    text-transform: uppercase;
}
.news-3 h3{
	font-size:20px;
	line-height:22px;
}
.news-container{
}
.news-content p{
	font-size:14px;
}
.video-overlay{
	position:absolute;
	color:#fff;
	font-size:80px;
	width:100%;
	text-align:center;
	display:table;
	vertical-align: middle;
	top: 50%;
	margin-top: -50px;
}
.main-image .video-overlay{
  position:absolute;
  color:#fff;
  font-size:80px;
  width:100%;
  text-align:center;
  display:table;
  vertical-align: middle;
  top: 50%;
  margin-top: -70px;
  margin-left: -20px;
  cursor: pointer;
}
.image-container:hover span{
	color:rgba(255,255,255,1.00);
}
.video-overlay span{
	display:table-cell;
	vertical-align:middle;
	color:rgba(255,255,255,0.6);
	-webkit-transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
	   -moz-transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
		 -o-transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
			transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955); /* easeInOutQuad */

	-webkit-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
	   -moz-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
		 -o-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
			transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955); /* easeInOutQuad */
}
.ads{
	min-height:800px;
}
.onead-cont{
  	margin-bottom:40px;
}
.img-thumbnail {
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
.onead h1{
	margin-top:0px;
	border-top:3px solid #48b648;
	border-bottom:3px solid #48b648;
	padding:10px;
	font-family: 'Amatic SC', cursive;
    font-weight: 300;
	font-size:36px;
	margin-bottom:10px;
  text-transform: uppercase;
    .status-tag{
        display: inline-block;
        width: 40px;
        height: 40px;
        vertical-align: middle;
        text-align: center;
        color: #fff;
	  -moz-border-radius: 50px;
	  -webkit-border-radius: 50px;
	  border-radius: 50px;
        margin-right: 5px;
        font-family: 'open-sans', sans-serif;
        font-size: 20px;
        line-height: 40px;
        font-weight: normal;
    }
}
.breadcrumbs{
	font-size:14px;
}
.breadcrumbs .spacer{
	vertical-align:middle;
	font-size:10px;
	color:#48b648;
}
.breadcrumbs a{
	color:#000;
}
.ad-owner{
	font-weight:600;
    font-size: 20px;
    color: #fff;
}
.location {
  color: #fff;
  margin-bottom: 10px;
}
.add-as-contact a{
	color:#000;
}
.add-as-contact a:hover{
	text-decoration:none;
}
.add-as-contact{
	display:inline-block;
	text-align:right;
}
.add-as-contact span{
	vertical-align: middle;
	font-size: 24px;
	display: inline-block;
	color: #48b648;
	margin-top: -5px;
	margin-bottom: -6px;
}
.ad-images .main-image{
	padding-bottom:100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
	position:relative;
	overflow:hidden;
    background-position: center;
    .icon{
      font-size: 60px;
      color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -30px;
      margin-right: -30px;
    }
}
.make-space{
	margin-top:10px;
}
.small-images{
	margin-top:10px;
    background-position: center;
}
.small-image{
	margin-bottom:10px;
	padding-bottom:100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.price-tag{
  text-transform: uppercase;
	color:#fff;
	position:absolute;
	top:0;
	left:5px;
	background:#48b648;
	padding:10px;
	font-family: 'Amatic SC', cursive;
  font-weight: 400;
	font-size:36px;
	padding-bottom:0px;
	left:-20%;
	top:-20%;
	-webkit-transition: all 600ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
	   -moz-transition: all 600ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
		 -o-transition: all 600ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
			transition: all 600ms cubic-bezier(0.455, 0.030, 0.515, 0.955); /* easeInOutQuad */

	-webkit-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
	   -moz-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
		 -o-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
			transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955); /* easeInOutQuad */
}
.left-line {
	position: absolute;
	right: -30px;
	top: 0px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 61px 30px 0 0;
	border-color: #48b648 transparent transparent transparent;
}
.bottom-line{
	width: 0;
	height: 0;
	position: absolute;
	bottom: -15px;
	left: 0px;
	border-style: solid;
	border-width: 15px 0px 0 0;
	border-color: #48b648 transparent transparent transparent;
}
.gray-container {
	background-color: #e9eae5;
	padding: 15px 15px 10px 15px;
  	margin-bottom: 10px;
}
.information-container {
	background:#48b648;
	padding:10px;
	margin-bottom:10px;
	color:white;
	font-size:12px;
}
.information-container a {
	color:#fff;
	text-decoration: none;
}
.information-container a:hover{
	color:#98d8cb;
	text-decoration:none;
}
.information-container a:hover .circle{
	border-color:#98d8cb;
}
.information-container ul{
	padding:0;
	margin:0;
}
.information-container ul li {
	list-style: none;
	line-height: 2.5;
}

.circle{
	color: white;
	display: inline-block;
	width: 25px;
	height: 25px;
	border: 2px solid #fff;
	text-align: center;
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
	border-radius: 50px;
	margin-right:3px;
	line-height:25px;
	vertical-align:middle;
	-webkit-transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
	   -moz-transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
		 -o-transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
			transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955); /* easeInOutQuad */

	-webkit-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
	   -moz-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
		 -o-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
			transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955); /* easeInOutQuad */
}
.onead p{
	font-size:14px;
}
.onead h3{
	font-size:16px;
	font-weight:600;
  margin: 0px 0px 1em 0px;
}
.pros ul{
	margin:0;
	padding:0;
}
.pros ul li span{
	vertical-align:middle;
}
.pros ul li{
  text-transform: capitalize;
	list-style:none;
	line-height:25px;
	vertical-align:bottom;
	font-weight:600;
	margin-bottom:5px;
	font-size:12px;
}
.check-round{
	background:#48b648;
	display:inline-block;
	width:25px;
	height:25px;
	text-align:center;
	line-height:25px;
	color:#fff;
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
	border-radius: 50px;
	vertical-align:middle;
}
.one-news-header-cont{
	border-left:3px solid #48b648;
	margin-left:5px;
	padding-left:10px;
	padding-top:10px;
	margin-right:5px;
	padding-right:10px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.gray-cont{
	background:#edecec;
    padding: 10px;
    margin-top: 10px;
    padding-bottom: 7px;
}
.one-news-header-cont h1{
	font-family: 'Amatic SC', cursive;
  font-weight: 400;
	margin-top:0px;
	font-size:45px;
  line-height: 45px;
  padding: 10px 0px;
  font-weight: 400;
  margin-top: -10px;

  text-transform: uppercase;
  small{
    font-family: 'open-sans', sans-serif;;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    margin-top: -20px;
    color: #484848;
  }
}

.bottom-info p{
  margin-bottom: 0px;
}

.one-news .main-image{
	padding-bottom:50%;
	background-position:center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
	margin-bottom:10px;
    position: relative;
    .bottom-info{
      position: absolute;
      bottom: 0px;
      left: 0px;
      background: rgba(255, 255, 255, 0.75);
      color: #000;
      padding: 10px;
      width: 100%;
      font-size: 14px;
      p{
        padding: 0;
        margin: 0;
      }
    }
}
.main-slogan{
	font-weight:600;
	font-size:16px;
}
.news-body h2{
	background:none;
	padding:0;
	color:#48b648;
	font-size:24px;
}
.navbar-default .navbar-brand{
	margin-top:-25px;
	font-size:70px;
	color:#fff;
}
.navbar-default .navbar-brand:hover{
	color:#FAFFE5;
}
.navbar-default{
	background:#48b648;
	border:none;
}
.navbar-default .navbar-toggle{
	color:#fff;
	border-color: #ffffff;
}
.navbar-default .navbar-toggle .icon-bar{
	background-color:#fff;
}
.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus{
	background-color:#fff;
}
.navbar-default .navbar-toggle:hover .icon-bar, .navbar-default .navbar-toggle:focus .icon-bar{
	background-color:#48b648
}
.navbar-default .navbar-nav>li>a{
	color:#fff;
}
.navbar-default .navbar-nav>li>a:hover, .navbar-default .navbar-nav>li>a:focus{
	color:#76d876;
}
.navbar-default .navbar-nav .open .dropdown-menu>li>a{
	color:#fff;
}
.navbar-default .navbar-nav .open .dropdown-menu>li>a:hover, .navbar-default .navbar-nav .open .dropdown-menu>li>a:focus{
	color:#76d876;
}
.navbar-default .icon{
	font-size:20px;
	vertical-align:middle;
}
.navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:hover, .navbar-default .navbar-nav>.open>a:focus{
	background:#fff;
	color:#48b648;
}
.navbar-default .navbar-collapse, .navbar-default .navbar-form{
	border-color:#76d876;
}
.navbar{
	margin-bottom:10px;
}
.modal-content, .btn, .form-control{
	-webkit-border-radius: 0px;
	-moz-border-radius: 0px;
	border-radius: 0px;
}
.b20 {
  margin-bottom: 20px;
}
.btn-primary{
	background-color:#48b648;
	color:#fff;
	border:none;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary.focus, .btn-primary:active, .btn-primary.active, .open>.dropdown-toggle.btn-primary{
	background:#3c923c;
}
.form-control:focus{
	border-color:#48b648;
	-webkit-box-shadow: inset 0px 1px 1px rgba(72, 255, 72, 0.6);
	-moz-box-shadow:    inset 0px 1px 1px rgba(72, 255, 72, 0.6);
	box-shadow:        	inset 0px 1px 1px rgba(72, 255, 72, 0.6);
}
.modal-title{
	font-family: 'Amatic SC', cursive;
  font-weight: 400;
	font-size:36px;
  font-weight: 400;
}
.nav-pills>li.active>a, .nav-pills>li.active>a:hover, .nav-pills>li.active>a:focus{
	background-color:#48b648;
}
.list-group-item.active>.badge, .nav-pills>.active>a>.badge{
	color:#48b648;
}

.panel-default > .panel-heading {
  color: #333333;
  background-color: #48b648;
  border-color: #48b648;
  font-size: 30px;
  font-family: 'Amatic SC', cursive;
  font-weight: 400;
  color: #fff;
  text-transform: uppercase;
}

.panel-heading{
  padding: 5px 15px;
}


.image-row{
  margin-bottom: 10px;
}

.preview-image{
  padding-bottom: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  border: 1px solid #ddd;
  overflow: hidden;
  position: relative;
}
.hide-form{
  z-index: 1000;
  position: absolute;
  top: -100%;
  left: 0px;
  -webkit-transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
  -moz-transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
  -o-transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
  transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955); /* easeInOutQuad */

  -webkit-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
  -moz-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
  -o-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
  transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955); /* easeInOutQuad */
}
.hide-breed {
  display:none;
}
.preview-image:hover .hide-form{
  top:0px;
}
.preview-image:hover .image-overlay{
  opacity: 1;
}

.pagination>.active>a, .pagination>.active>span, .pagination>.active>a:hover, .pagination>.active>span:hover, .pagination>.active>a:focus, .pagination>.active>span:focus{
  background-color: #48b648;
  border-color: #48b648;
}
.pagination>li>a, .pagination>li>span{
  color: #48b648;
}

.paginationContainer {
	display: none;
}
.addThisContainer {
	display: none;
	margin: 15px 0;
	&.show {
		display:block;
	}
}

.image-overlay{
  -webkit-transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
  -moz-transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
  -o-transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
  transition: all 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955); /* easeInOutQuad */

  -webkit-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
  -moz-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
  -o-transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
  transition-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955); /* easeInOutQuad */
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  color:#fff;
  cursor: pointer;

  .icon{
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 30px;
    margin-top: -15px;
    margin-left: -15px;
  }
}

.myplace{
  border: 1px solid #ddd;
  padding: 0px;
  margin-bottom: 8px;
}

#sortable {
  margin: 0;
  padding: 0;
}
.ui-sortable-handle{
  display: block;
  margin-bottom: 10px;
}

#sortable li {
  display: block;
  float: left;
}

.stepwizard{
  margin: 20px 0px;
}

.stepwizard-step p {
  margin-top: 10px;
}

.stepwizard-row {
  display: table-row;
}

.stepwizard {
  display: table;
  width: 100%;
  position: relative;
}

.stepwizard-step button[disabled] {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}

.stepwizard-row:before {
  top: 14px;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 100%;
  height: 1px;
  background-color: #ccc;
  z-order: 0;

}

.stepwizard-step {
  display: table-cell;
  text-align: center;
  position: relative;
  width: 25%;
}

.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  border-radius: 15px;
}

.fancybox-wrap, .fancybox-wrap *{
  -moz-box-sizing: content-box !important;
  -webkit-box-sizing: content-box !important;
  -safari-box-sizing: content-box !important;
  box-sizing: content-box !important;
}
.big-title{
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}
.btn-primary{
  border: 1px solid #48b648;
  color:#fff !important;
}
.btn-primary:hover{
  border: 1px solid #48b648;
  color: #FFFFFF !important;
}
.btn-danger{
  color: #fff !important;
}
.btn-danger:hover{
  color:#d9534f !important;
  border:1px solid #d9534f;
}
.btn-default:hover{
  border:1px solid #48b648;
}
.btn-default.active {
	border:1px solid #48b648;
	color: #FFFFFF;
	background-color: #5cb85c;
}
.btn.facebook {
  background-color: #3b5998;
  border: 1px solid #3b5998;
}
.btn.google {
  background-color: #F44336;
  border: 1px solid #F44336;
}
.btn.facebook:hover, .btn.facebook:active {
  color: #3b5998!important;
  border: 1px solid #3b5998;
}
.btn.google:hover, .btn.google:hover {
  color: #F44336!important;
  border: 1px solid #F44336;
}

.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}
.small-image .icon{
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -15px;
  margin-left: -15px;
  font-size: 30px;
  color: #fff;
}

.hide-contact{
  display: none;
}

label.myLabel input[type="file"] {
  position: fixed;
  top: -1000px;
}

/***** Example custom styling *****/
.myLabel {
  border: 1px solid #48b648;
  background: #48b648;
  color: #fff !important;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 400;
}
.myLabel:hover {
  background: #48b648;
}
.myLabel:active {
  background: #48b648;
}
.myLabel :invalid + span {
  color: #fff;
}
.myLabel :valid + span {
  color: #fff;
}

.one-video-container{
  margin: 20px 0px;
  margin-top: 0px;
}

.thumbimage{
  display: none;
}

.news-pad{
  padding: 0px 10px 30px 10px;
}
.adStatus {
  position: absolute;
  right: 20px;
  top: 15px;
}
a.infobox .glyphicon {
  color:#48b648;
}
.footer-container{
  background: #31322c;
  padding-top: 40px;
  padding-bottom: 30px;
  color: #e9e2d6;
  font-size: 16px;
  a{
    color: #e9e2d6;
  }
  .title{
    color: #48b648;
    font-size: 16px;
    font-weight: 300;
    text-transform: uppercase;
    line-height: 28px;
  }
  ul{
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    li{
      padding: 0;
      margin: 0;
      list-style: none;
      line-height: 1.4em;
      font-size: 14px;
    }
  }
  .social-footer{
    color: #48b648;
    letter-spacing: 1px;
    a{
      color: #48b648;
      text-transform: uppercase;
    }
    li{
      padding: 0 20px;
    }
    hr{
      border-color: #48b648;
    }
	span {
	  color:white;
	}
  }
}
.logos-title{
  h3{
    font-family: 'Amatic SC', cursive;
    font-weight: 400;
    font-size: 35px;
    color: #48b648;
	text-transform: uppercase;
  }
}
.author{
  margin-top: 20px;
  ul{
    margin: 0;
    padding: 0;
    li{
      list-style: none;
      line-height: 23px;
    }
  }
}

.author-image {

  width: 50px;
  height: 50px;

}

.news-body img{
  width: 100%;
}
.popover{
  max-width:600px;
}
.gallery{
  color: #000;
  font-size: 14px;
  line-height: 28px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -65px;
  margin-top: -65px;
  text-align: center;
  width: 130px;
  height: 130px;
  background: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 150px;
  -moz-border-radius: 150px;
  border-radius: 150px;
  .gallery-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -33px;
    margin-left: -44px;
  }
  .icon-gallery{
    vertical-align: middle;
    display: block;
    font-size: 40px;
    text-align: center;
  }
}

.caption h3 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
@media (min-width: 1250px) {
  .header .green .cont{
    height: 200px !important;
  }
}



@media (min-width: 768px) and (max-width: 991px) {
	.profile .light-green .name-cont{
		font-size:18px;
	  	text-transform: uppercase;
	}
  .logo-cont .logo-text {
    display: block;
    color: #fff;
    font-family: 'Amatic SC', cursive;
    font-weight: 400;
    margin-top: -70px;
    font-size: 24px;
    line-height: 21px;
    text-align: center;
  }
  .logo-cont span.icon-logo {
    font-size: 110px;
    color: #fff;
    vertical-align: top;
  }
}
@media (max-width: 991px) {

	.menu-cont ul li ul{
		width:65%;
	}
	.menu-cont ul li a .icon{
		padding-right:10%;
		width:30%;
	}
}
@media (max-width: 767px){
	.add-as-contact{
		text-align:left;
	}
	.news-6 .image-container .bottom-info, .news-3 .image-container .bottom-info{
		display:none !important;
	}
	.image-container:hover span{
		font-size:40px !important;
	}
	.news-3 .image-container{
		padding-bottom:50%;
	}
	.bottom-text{
		bottom:0px;
	}
	.menu-cont{
		display:none;
	}
	.header .green-line{
		display:none;
	}
	.main-container{
		padding:5px;
	}
	.content-container{
		padding-right:5px;
	}
}
@media (max-width: 764px){
	.pros ul li,
	.information-container a{
		font-size:14px;
	}
	.postDetailsContainer .postDetails {
		border: none;
	}
	.postContainer .postContent {
		border: none;
		padding: 0px;
	}
	.postContainer {
		border: none;
		border-top: 1px solid #d7d6d6;
		.postDetails {
			margin-bottom: 15px;
		}
		.commentsContainer {
			.commentBoxContainer {
				margin-top: 10px;
				margin-bottom: 10px;
				border: 1px solid #d7d6d6;
			}
		}
	}
	.addAttachmentContainer {
		.btn-primary {
			margin-top:10px;
		}
	}

}

@media print {
  .thumbimage{
    display: block;
  }
  .header, .profile, .main-menu{
    display: none;
  }
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
	select, textarea, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"] { font-size: 16px; }
}